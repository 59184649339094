import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog2: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/soyol-erdene.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Б.Соёл-Эрдэнэ
          </Text>
          <Title level={3}>
            Хүүхэд бүр өөр өөрийн гэсэн сурах арга барилтай
          </Title>
          <Paragraph style={{ fontSize: 16 }}>
            Хүүхэд бүр өөр өөрийн гэсэн сурах арга барилтай Хүүхэд бүрийн энгийн
            үйлдлүүд нь тэдний аливаа зүйлийг сурах арга барилынх нь түлхүүр
            юм.Тиймээс хүүхдийнхээ ямар арга барилаар суралцах чадвартайг таньж
            мэдэх нь цаашид тэдний давуу талаа нээх,аливаа зүйлийг хялбархан
            сурах чадварыг нь хөгжүүлэх түлхэц нь болдог.
          </Paragraph>
          <Paragraph style={{ fontSize: 16 }}>
            Хүүхдээ <li>- Харааны</li> <li>- Хөдөлгөөний </li>
            <li>- Хөдөлгөөний</li> <li>- Унших бичих</li> гэсэн үндсэн 4 арга
            барилын алинд тохирч байгааг тодорхойлохын тулд маш сайн ажиглах
            хэрэгтэй.
            <li>
              <b>Харааны:</b>
            </li>
            Мэдээллийг нүдээрээ хүлээж авдаг,тэмдэглэх,тодруулах зэргээр
            дамжуулан илүү сайн тогтоодог.Унших бичих дуртай,эмх цэгцтэй хувийн
            зохион байгуулалт сайтай байдаг. Үзүүлэн ,зураг,видео,зурагт ном
            ,олон өнгө бүхий карттай нөхөрлүүлж шинэ зүйлсийг ойлгуулах
            хэрэгтэй.
            <li>
              <b>Сонсголын:</b>
            </li>
            Ой тогтоолт сайтай,ярих сонсох чадвар нь сайн тул сонсож ойлгох
            дасгал,даалгавар сайн хийдэг.Чангаар унших ярилцах сонсох харилцах
            дуртай.Амархан цээжилдэг.Дуулах ,олон асуулт асуух дуртай. Өгүүллэг
            дуу шүлэг ашиглан шинэ зүйлсийг ойлгуулах хэрэгтэй.
            <li>
              <b>Хөдөлгөөний:</b>
            </li>
            Будах, зурах ,бүжиглэх,шинжлэх ухааны туршилт хийх,хоол хийх зэрэгт
            илүү сонирхолтой байдаг.Өөрийн биеэр туршиж оролдож үзэх хэлбэрээр
            аливааг сурдаг.Тоглоом тоглуулах,гарын ур шаардсан зүйлс хийлгээд
            үзээрэй.
            <li>
              <b>Унших бичих:</b>
            </li>
            Хараа, сонсгол хоёрыг хослуулсан чадвартай байдаг.Уран сэтгэмж ой
            тогтоолт сайтай.Аливаад анхааралтай тууштай байдаг.Олон хувилбартай
            ажиллуулах,бодлого бодох,ном унших,зохион бичлэг хийхдээ сайн.Санааг
            нь олон хувилбараар бичүүлэх,Тэмдэглэлийг нь дахин дахин уншуулах
            зэргээр сурах арга барилыг нь дэмжих хэрэгтэй юм байна. “Удаан ч
            гэсэн өөрийнхөө хэмжээнд ахиц гаргаад урагшилж байгаа хүүхдүүдийн
            урамыг битгий хугалаарай.” /Мөрөөдлийн жигүүр боловсролын төвийн
            Багш: Б.Соёл-Эрдэнэ/
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog2;
