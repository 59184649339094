import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const BlogContainer: React.FC = () => {
  const navigate = useNavigate();

  const handleCardClick = (blog_url: string) => {
    navigate(blog_url);
  };

  return (
    <div>
      <Row>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog1")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/blogs/say_it_loud.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={120} src="/images/profile/suvd-erdene.png" />
                  }
                  title="SAY IT LOUD!"
                  description="Англи хэлний багш: Сувд-Эрдэнэ"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list"
            onClick={() => handleCardClick("/blog2")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/blogs/hulan_blog.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={120} src="/images/profile/soyol-erdene.png" />
                  }
                  title={"Хүүхэд бүр өөр өөрийн \n гэсэн сурах арга барилтай"}
                  description={"Англи хэлний багш: Соёл-эрдэнэ"}
                  className="meta-text"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list"
            onClick={() => handleCardClick("/blog3")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/blogs/hulan_blog.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={120} src="/images/profile/khulan.png" />
                  }
                  title={"Англи хэл сурах \n хялбар арга"}
                  description={"Англи хэлний багш: Хулан"}
                  className="meta-text"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list"
            onClick={() => handleCardClick("/blog4")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src="/images/blogs/eq.png" className="blog-index-list-img" />
              <div>
                <Meta
                  avatar={<Avatar size={120} src="/images/profile/naraa.png" />}
                  title="EQ чадвар хөгждөг"
                  description="Англи хэлний багш: Наранцэцэг"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{}}
            className="dream-blog-list"
            onClick={() => handleCardClick("/blog5")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/blogs/mate.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={120} src="/images/profile/oyunkhuu.png" />
                  }
                  title={
                    "Бодлого нь математик \n сургалтыг амьдралтай \n холбох гүүр болж өгдөг. "
                  }
                  description="Англи хэлний багш: Оюунхүү"
                  className="meta-text"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list"
            onClick={() => handleCardClick("/blog6")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/blogs/why.jpg"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={120} src="/images/profile/oyuntungalag.png" />
                  }
                  title={"Зөвхөн Англи үсэг \n битгий заагаарай."}
                  description="Англи хэлний багш: Б.Оюунтунгалаг"
                  className="meta-text"
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BlogContainer;
