import React, { ReactNode } from "react";
import { Layout } from "antd";
import DreamHeader from "./Header";
import DreamFooter from "./Footer";
import MobileMenu from "./Mobile_menu";

const { Content } = Layout;

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Layout>
      <DreamHeader />
      <MobileMenu />
      <Content style={{ width: "100%", margin: "0 auto" }}>{children}</Content>
      <DreamFooter />
    </Layout>
  );
};

export default MainLayout;
