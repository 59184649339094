import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog4: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/naraa.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Наранцэцэг
          </Text>
          <Title level={3}>EQ чадвар хөгждөг</Title>
          <Paragraph>
            EQ чадвар гэдэг нь өөрийн болон бусдын сэтгэл хөдлөлийг таних,
            түүний нөлөөллийг ойлгох, тохиромжтой хариу үйлдэл үзүүлж, ухаалгаар
            удирдан зохицуулах чадвар юм. Хүүхэд сэтгэл хөдлөлийн талаар илүү
            сайн мэдэж авснаар, өөрийн болон бусдын сэтгэл хөдлөлийг ойлгож,
            ямар үед хэрхэн зөвөөр илэрхийлэхээ ухаарч суралцдаг. EQ чадвар нь 5
            иж бүрдэлтэй.
          </Paragraph>
          <li>
            <b>Үүнд:</b>
          </li>
          <Paragraph>
            <b>1.</b> Өөрийн сэтгэл хөдлөл, мэдрэмжийг танин мэдэх /Self
            awareness/- Энэ нь ямар нэг нөхцөл байдалд өөрийн сэтгэл хөдлөл,
            мэдрэмжээ танин мэдэх EQ-ийн суурь чадварын нэг юм. Би юу мэдэрч
            байна вэ? Би ууртай байна.
          </Paragraph>
          <Paragraph>
            <b>2.</b> Өөрийн сэтгэл хөдлөл, мэдрэмжээ удирдах чадвар /Self
            control/- Эхний чадвар буюу мэдрэмжээ танин мэдсэнээр хүүхэд тухайн
            нөхцөл байдалдаа тохируулан өөрийгөө удирдах чадвар юм. Би уур
            бухимдлаа хэрхэн зөвөөр гадагшлуулах вэ? Би агаар амьсгалаад гадаа
            10 минут сууж болох юм байна.
          </Paragraph>
          <Paragraph>
            <b>3.</b> Өөрийгөө идэвхжүүлэх чадвар /Motivation/- Ямар нэгэн
            асуудал бэрхшээл тулгарахад хэрхэн түүнийгээ даван туулж, өөрийгөө
            сэдэлжүүлэх чадвар юм. Би шалгалтандаа уначихжээ, гэхдээ зүгээрдээ
            ахиад давтаад шалгалтаа өгөөд үзье.
          </Paragraph>
          <Paragraph>
            <b>4.</b> Бусдын сэтгэл хөдлөл, мэдрэмжийг ойлгох чадвар /Empathy/-
            Энэ нь бусдын оронд өөрийгөө тавьж үзэх, бусдыг ойлгох чадвар юм.
            Манай найз өнөөдөр нэг л дуугай хураагаад юм ярихгүй байна даа, ямар
            нэгэн хэцүү зүйл тохиолдсон юм байхдаа.
          </Paragraph>
          <Paragraph>
            <b>5.</b> Харилцааны чадвар /Social skill/ - Бусадтай зөв зохистой
            харилцах, багаар ажиллах, оновчтой шийдвэр гаргах зэрэг сүүлийн
            үеийн чухал чадварууд болох “Зөөлөн ур чадвар”-ууд багтдаг.
          </Paragraph>
          <Paragraph>
            Тэгэхээр EQ чадвар бол хөгждөг чадвар юм. Хүний амьдралын амжилтын
            20%-ийг IQ бүтээдэг бол үлдсэн 80%-ийн амжилт нь EQ хэр хөгжсөнөөс
            шалтгаалдаг. IQ таныг орох ёстой хаалган дээр тань авчирна. EQ таныг
            тэр хаалгаар ороход хэр удаан, хэр хол явахад хөтөлнө.
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog4;
