import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog5: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/oyunkhuu.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Оюунхүү
          </Text>
          <Title level={3}>
            Бодлого нь математик сургалтыг амьдралтай холбох гүүр болж өгдөг.{" "}
          </Title>
          <Paragraph>
            Хүүхдүүд бодлого бодох явцдаа амьдралд хэрэгцээтэй практик чадвар
            дадал эзэмшдэг нь миний хичээл заадаг хүүхдүүдийн олон жишээнээс
            харагддаг. Хамгийн энгийн зүйл гэвэл хүүхдүүд дэлгүүрт ороод өөрт
            байгаа мөнгөндөө худалдан авалт хийж сурч, амьдарч байгаа байрныхаа
            ашигтай талбай тооцоож, аялалаар явах үедээ очих туулах км болон
            цагаа зөв тооцоолж байсан гэх мэт олон сайхан жишээг ирж хэлдэг.
          </Paragraph>
          <Paragraph>
            Бага ангидаа өгүүлбэртэй бодлогоо сайн бодож сурсан хүүхэд дараагийн
            шатнуудад хими, физик, газар зүйн бодлогуудыг амжилттай сайн бодож
            чаддаг болдог. Манай боловсролын төвийн заадаг өгүүлбэртэй бодлого
            бодох алхамууд:
          </Paragraph>
          <Paragraph>
            <b>1.</b> Өгүүлбэртэй бодлогоо ойлгох хүртлээ сайн унших. Ойлгох
            хүртлээ хэдэн ч удаа уншиж болно гэж хэлдэг. Ойлгож уншсанаараа
            бодлогоо бодоход ямар үг, өгүүлбэр чухал байна вэ? гэдгийг харж
            чаддаг.
          </Paragraph>
          <Paragraph>
            <b>2.</b> Зураглал болон товч бичиглэл хийдэг
          </Paragraph>
          <Paragraph>
            <b>3.</b> Бодолтоо дарааллын дагуу хийдэг
          </Paragraph>
          <Paragraph>
            <b>4.</b> Бодолтоо шалгах үе дээр хүүхдүүд өөрийгөө зөв бодсон
            эсэхийг хянаж хардаг.
          </Paragraph>
          <Paragraph>
            <b>5.</b> Бодолтоо бусдад тайлбарлах үе шат дээр бодолтоо бусдад
            тайлбарласнаар ярих чадвараа нэмэгдүүлдэг.
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog5;
