import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog6: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/oyuntungalag.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Оюунтунгалаг
          </Text>
          <Title level={3}>Зөвхөн Англи үсэг битгий заагаарай. </Title>
          <Paragraph>
            Англи хэл сурах үйл явцын нэг чухал хэсэг нь үсэг болон авиаг зөв
            таниулах юм. Англи хэлний үсэг болон авиаг бага насны хүүхдэд
            заахдаа олон сонирхолтой, хөгжилтэй аргууд ашиглан таниулах нь илүү
            их үр дүнтэй. Миний хувьд доор аргуудыг сургалтынхаа явцад илүү их
            ашигладаг. Шинэ үсэг таниулахдаа зөвхөн нэрийг дурдаад цээжлүүлээд
            орхих бус авиаг нь хамтад нь хэлүүлж хэрэглүүлээрэй. Нэг авиатай
            үсгийг эхлэн таниулах.
          </Paragraph>
          <Paragraph>
            <b>Дараа нь олон дуудлагатай үсгээ заах:</b>
          </Paragraph>
          <Paragraph>
            <b>1.</b> Нэг удаа олон үсэг үзэхгүй 3-4 үсэг үздэг
          </Paragraph>
          <Paragraph>
            <b>2.</b> Үсгүүдийг том жижгээр нь таниулах, тогтмол давтуулдаг
          </Paragraph>
          <Paragraph>
            <b>3.</b> Тухайн үсэгтэй холбоотой дуу, шүлэг, үгсийг бэлтгэдэг
          </Paragraph>
          <Paragraph>
            <b>4.</b> Дүрслэл тоглоом ашигладаг г.м
          </Paragraph>
          <Paragraph>
            Мөн суралцах явцад нь хүүхдүүдээ үргэлж урамшуулахаа мартаж болохгүй
            шүү. Үсгийн нэр болон авиаг хэлүүлэхдээ болон дэвтэрт нь бичүүлэхийн
            өмнө агаарт гараар 2-3 удаа бичүүлээд дараа нь дэвтэрт нь
            бичүүлээрэй.
          </Paragraph>
          <Paragraph>
            Тэгэхээр EQ чадвар бол хөгждөг чадвар юм. Хүний амьдралын амжилтын
            20%-ийг IQ бүтээдэг бол үлдсэн 80%-ийн амжилт нь EQ хэр хөгжсөнөөс
            шалтгаалдаг. IQ таныг орох ёстой хаалган дээр тань авчирна. EQ таныг
            тэр хаалгаар ороход хэр удаан, хэр хол явахад хөтөлнө.
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog6;
