import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Typography, Row, Col, Card } from "antd";
import DownloadContainer from "src/Containers/DownloadFile";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const DownloadFilePage: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 1200,
        margin: "0 auto",
        backgroundColor: "#fff",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
          <DownloadContainer />
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default DownloadFilePage;
