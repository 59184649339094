import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog3: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Avatar
            size={100}
            src="/images/profile/khulan.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Хулан
          </Text>
          <Title level={3} style={{ fontWeight: 500, textAlign: "center" }}>
            Англи хэл сурах хялбар арга
          </Title>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Image src="/images/blogs/hulan_blog.png" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontSize: 16, textAlign: "center" }}>
                When students are reading extensively they <b>READ:</b>
                <b>R</b>ead quickly and <b>E</b>njoyably with Adequate
                comprehension so they Don’t need a dictionary
              </Paragraph>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Paragraph
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  color: "blue",
                  borderTop: "1px solid  blue",
                  borderBottom: "1px solid  blue",
                  paddingTop: 15,
                  paddingBottom: 15,
                  fontFamily: "Calibri, sans-serif",
                  fontStyle: "italic",
                }}
              >
                Гадаад хэл суралцагч сурч буй хэлээрээ ойлгоход хялбар ном болон
                сонсох материалыг их хэмжээгээр тогтмол уншихыг ER аргачлал
                гэнэ.
              </Paragraph>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Paragraph
                style={{
                  fontSize: 14,
                }}
              >
                Суралцагч өргөн хүрээнд уншлага хийх явцдаа мэдлэгээ тэлж
                байдаг. Өөрөөр хэлбэл, тухайн номын утга агуулгыг мэдэхээр
                уншихдаа шинэ мэдлэгийг ухамсаргүйгээр олж авдаг.{" "}
                <li>
                  <b>Юунаас эхлэх вэ?</b>
                </li>
                <li style={{ fontStyle: "italic", color: "#FF7F50" }}>
                  01 Хялбаршуулсан ном
                </li>{" "}
                (Graded Readers) Буюу хэл суралцагчдын унших хурд, ойлгох
                чадварыг хөгжүүлж, ном унших “жинхэнэ” дадал суулгахад зориулан
                боловсруулж бичсэн зохиолуудыг унших
                <li style={{ fontStyle: "italic", color: "#FF7F50" }}>
                  02 Түвшингээ мэдэх Номоо сонгоно
                </li>
                (түвшингээ таамаглана) Дурын нэг нүүрийг нээгээд уншиж үзнэ.
                Мэдэхгүй шинэ үгээ тоолно. 5-аас дээш үг байвал танд хүндэдсэн
                ном байгаа учраас өөр ном сонгох. 4 байвал уншиж чөлөөтэй
                ойлгоход хүндрэлтэй. 2-3 шинэ үг байвал таны унших түвшин мөн
                байна гэсэн үг. 1 л шинэ үг байвал хэт амархан байна гэж үзнэ.
                <li style={{ fontStyle: "italic", color: "#FF7F50" }}>
                  03 Жаргаж унших
                </li>
                ER- ын нэг зорилго нь таашаал авч, хөгжилтэй байхын тулд унших
                юм. Илүү их унших тусам өөртөө итгэх итгэл, урам зориг
                нэмэгдсэнээр хэлний чадвараа дээшлүүлж, илүү үр дүнтэй
                суралцахад тань тусална.
              </Paragraph>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Image src="/images/blogs/hulan_blog3.png" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <li>
                <b>Яагаад ER гэж? </b>
              </li>
              <li>
                1. Үг хэллэгийг сурах бичигт өгөгдсөн жишээнээс өөрөөр жинхэнэ
                бодит эхэд хэрхэн хэрэглэгддэгийг уншиж мэднэ{" "}
              </li>
              <li>2. Үгийн сан маш хурдацтай нэмэгдэнэ (meme & slang)</li>
              <li>
                3. Англи хэлээрээ сэтгэдэг болно (өөртэйгөө Англиар яриад
                эхэлдэг)
              </li>
              <li>
                4. Өөртөө итгэх итгэл, урам зориг, сурахын баяр баясгаланг
                мэдэрч, хүсэл тэмүүлэлтэй болгоно
              </li>
              <li>5. Уншиж ойлгох, сонсох чадвар нэмэгдэнэ</li>
              <li>6. Дүрмийн мэдлэг сайжирна Юуг анхаарах вэ?</li>
              <li>
                <b>Юуг анхаарах вэ?</b>
              </li>
              <li>1. Өөрийн сонирхсон номыг унших</li>
              <li>2. Толь бичиг ашиглахгүй байх </li>
              <li>
                3. Зөвхөн номны хавтас, зураг чимэглэлийг харах бус номны
                хэсгээс уншиж үзэх
              </li>
              <li>
                4. Урт хугацаанд тууштай унших 5. Өөрийн түвшинд таарсан номыг
                сонгох (1 минутанд 150-200 үг уншиж чадахуйц)
              </li>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              <Paragraph>
                Эх сурвалж: <a>https://erfoundation.org</a>
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog3;
