import React from "react";
import { Row, Col, Typography, Space, Avatar } from "antd";
import MainLayout from "src/Layouts/MainLayout";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";

const { Title, Paragraph, Text, Link } = Typography;

interface DataItem {
  id: number;
  title: string;
  description: string;
  video_url: string;
  teacher1_name: string;
  teacher2_name: string;
  teacher1_img_url: string;
  teacher2_img_url: string;
}

const data: DataItem[] = [
  {
    id: 1,
    title: "Жишээ ярья подкаст ДУГААР 1. Ангийн дүрмээр аврагдсан нь",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=hNeOW20ruds",
    teacher1_name: "Teacher: Hulan",
    teacher2_name: "Teacher: Suvdaa",
    teacher1_img_url: "/images/profile/hulan.png",
    teacher2_img_url: "/images/profile/suvdaa.png",
  },
  {
    id: 2,
    title:
      "Жишээ ярья подкаст ДУГААР 2. Багшийн сэтгэл хөдлөл хүүхдэд нөлөөлөх нь",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=c4GcLIpkGE4&t=175s",
    teacher1_name: "Teacher: Hulan",
    teacher2_name: "Teacher: Suvdaa",
    teacher1_img_url: "/images/profile/hulan.png",
    teacher2_img_url: "/images/profile/suvdaa.png",
  },
  {
    id: 3,
    title: "Жишээ ярья подкаст ДУГААР 3: Алдсан ч Айхгүй",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=gElKj9xcuzM&t=263s",
    teacher1_name: "Teacher: Naraa",
    teacher2_name: "Teacher: Suvdaa",
    teacher1_img_url: "/images/profile/naraa.png",
    teacher2_img_url: "/images/profile/suvdaa.png",
  },
  {
    id: 4,
    title: "Жишээ ярья подкаст ДУГААР 4. Тархийг нь ХОРДУУЛАХГҮЙГЭЭР сургая",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=UPS09TY1vVg&t=21s",
    teacher1_name: "Teacher: Hulan",
    teacher2_name: "Teacher: Badamtsetseg",
    teacher1_img_url: "/images/profile/hulan.png",
    teacher2_img_url: "/images/profile/badamtsetseg.png",
  },
];

const CardDetail: React.FC = () => {
  type Params = {
    id: string;
  };
  const { id } = useParams<Params>();

  if (!id) {
    return <div>Card ID is missing</div>; // Handle undefined `id`
  }

  const foundItem = data.find((item) => item.id === parseInt(id, 10));

  //if (!card) {
  //return <div>Card not found</div>;
  //}
  return (
    <MainLayout>
      <div
        style={{
          backgroundColor: "#002333",
          color: "#fff",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            paddingTop: "104px",
            maxWidth: 1352,
            margin: "0 auto",
            display: "flex",
            backgroundColor: "#002333",
            alignItems: "center",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={8} lg={15} xl={15}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={foundItem?.video_url}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={9}
              xl={9}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Title
                level={2}
                style={{
                  color: "#ffffff",
                  fontSize: 20,
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {foundItem?.title}
              </Title>
              <Row gutter={[24, 24]} style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div style={{ left: 0 }}>
                    <Avatar
                      size={80}
                      src={foundItem?.teacher1_img_url}
                      style={{ marginRight: "15px" }}
                    />
                    <Text
                      style={{
                        fontSize: "16px",
                        color: "#ffffff",
                      }}
                    >
                      {foundItem?.teacher1_name}
                    </Text>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div style={{ left: 0 }}>
                    <Avatar
                      size={80}
                      src={foundItem?.teacher2_img_url}
                      style={{ marginRight: "15px" }}
                    />
                    <Text style={{ fontSize: "16px", color: "#ffffff" }}>
                      {foundItem?.teacher2_name}
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div style={{ padding: "20px" }}>
              <Title level={2} style={{ color: "#ffffff" }}>
                Podcast тухай
              </Title>
              <Paragraph style={{ color: "#ffffff", fontSize: "16px" }}>
                МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.
              </Paragraph>
            </div>
          </Row>
        </Space>
      </div>
    </MainLayout>
  );
};

export default CardDetail;
