import React from "react";
import { Layout, Row, Col } from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  createFromIconfontCN,
} from "@ant-design/icons";

const { Footer } = Layout;

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js", // Example CDN script URL
});

interface Pane {
  title: string;
  key: string;
  className: string;
  icon: React.ReactNode;
}

const DreamFooter: React.FC = () => {
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#002333",
              color: "#ffffff",
              position: "relative",
              bottom: "0",
            }}
          >
            <div>
              <a
                href="https://www.facebook.com/groups/889103484968491/?hoisted_section_header_type=recently_seen&multi_permalinks=1565917047287128"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlined
                  style={{ fontSize: "24px", margin: "0 10px", color: "#fff" }}
                />
              </a>
            </div>
            <p>©2024 Dreamwigs. All rights reserved.</p>
          </Footer>
        </Col>
      </Row>
    </div>
  );
};

export default DreamFooter;
