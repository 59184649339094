import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog1: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/suvd-erdene.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Сувд-Эрдэнэ
          </Text>
          <Title level={3}>SAY IT LOUD!</Title>
          <Paragraph style={{ fontSize: 16 }}>
            Шинэ хэл сурахад бидний хамгийн эхний давах даваа бол шинэ үг
            цээжлэх мөн үгсийн сантай болох юм. Англи хэлийг хоёрдогч хэлээрээ
            сурч буй сурагчидад үгийн сангаа нэмэгдүүлэх, дуудлагаа сайжруулах
            үр дүнтэй арга бол шинэ үгсийг чанга хэлж давтах явдал юм. Энэ
            дасгал нь энгийн мэт санагдаж болох ч дараах хэд хэдэн шалтгааны
            улмаас хэл сурах үйл явцад маш чухал үүрэг гүйцэтгэдэг.
          </Paragraph>
          <Paragraph style={{ fontSize: 16 }}>
            <b>1.</b> Дуудлага сайжирна (Pronunciation improvement) : Шинэ үг
            сурахдаа сурагчид тухайн үгийн зөв дуудлагыг олон давтан сонсдогч
            өөрийгөө яаж хэлж байгааг тэр болгон анхаарч давтдаггүй. Үгийг
            чангаар давтаж дуудах нь сурагчид өөрийн дуудлагыг сонсох буцаад
            дуудлагаа засах ялангуяа төрөлх хэлэнд нь байдаггүй авиаг засахад
            тусалдаг.{" "}
          </Paragraph>
          <Paragraph style={{ fontSize: 16 }}>
            <b>2.</b> Санах ойг сайжруулдаг (Enhance learning) : Дуу хоолойн
            давталт нь тухайн үгийг харах, сонсох мөн дуу авиа гаргах зэрэг олон
            мэдрэхүйг ажилуулдаг тул тухайн үгийг буцааж санахад илүү хялбар
            болгодог.
          </Paragraph>
          <Paragraph style={{ fontSize: 16 }}>
            <b>3.</b> Идэвхтэй суралцах (Active learning) : Үгийг чангаар дуудах
            үедээ сурагч суралцах процесст идэвхтэй оролцож байдаг бөгөөд хэл
            сурах хөгжилтэй хэрнээ шантармаар урт удаан аялалдаа үргэлжлүүлэн
            суралцахад тусалдаг.
          </Paragraph>
          <Paragraph style={{ fontSize: 16 }}>
            <b>4.</b> Булчингийн ой санамж (Muscle memory) : Үг цээжлэх нь
            булчингаа хөгжүүлэхтэй адил зүйл юм. Шинэ үгийг чангаар дуудаж
            давтах нь тухайн үгийг хэлэхэд амны булчингуудийг дасгах мөн зөв
            дуудлага хийхэд шаардлагатай булчингийн ой санамжийг хөгжүүлж, илүү
            чөлөөтэй ярихад тусbалдаг.
          </Paragraph>
          <Paragraph style={{ fontSize: 16 }}>
            <b>5.</b> Өөртөө итгэх итгэлийг бий болгодог (Builds confidence) :
            Тогтмол чангаар дуудах дасгал хийх нь сурагчид өөртэй итгэлтэйгээр
            шинэ үгээ ашиглах, ярих итгэлийг нь нэмэгдүүлдэг. Нэгэнт тухайн үгээ
            зөв дуудаж байгаадаа итгэлтэй, амны булчингууд тухайн үгийг хэлээд
            дассан бол суралцагч сурсан үгээ өөртэй итгэлтэйгээр өдөр тутамдаа
            ашиглаж эхэлдэг.
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog1;
