import React from "react";
import { Row, Col, Typography, Space, Image, Descriptions, Button } from "antd";
import MainLayout from "src/Layouts/MainLayout";

const { Title, Paragraph, Text, Link } = Typography;

const OnlineWebinar: React.FC = () => {
  return (
    <MainLayout>
      <div
        style={{
          backgroundColor: "#002333",
          color: "#fff",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            paddingTop: "104px",
            maxWidth: 1352,
            margin: "0 auto",
            display: "flex",
            backgroundColor: "#002333",
            alignItems: "center",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={15} xl={15}>
              <Image
                className="online-webinar-borcher"
                src="/images/webinar/borcher1.jpeg"
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={9}
              xl={9}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Title
                level={2}
                style={{ color: "#ffffff", fontSize: 24, textAlign: "center" }}
              >
                Энэхүү webinart бүртгүүлэхийг хүсвэл доорх товч дарна уу?
              </Title>
              <Button
                style={{
                  fontWeight: 700,
                  color: "#ffffff",
                  background: "#027379",
                }}
                size="large"
              >
                Webinart Бүртгүүлэх
              </Button>
            </Col>
          </Row>
        </Space>
      </div>
    </MainLayout>
  );
};

export default OnlineWebinar;
