import React from "react";
import { Col, Typography, Row, Card, Image, List, Button } from "antd";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Meta } = Card;

// Sample book data
const books = [
  {
    title: "Миний танилцуулга",
    author: "Dreamwings",
    cover: "/images/download_file/download_file1.png",
    link: "https://www.dropbox.com/scl/fi/0f5jiofcnu08eeisapnei/.pdf?rlkey=3ksdbcm62x97mvo19qljxwdu4&st=jgs7mmxx&dl=0",
  },
  {
    title: "1-100 numbers blocks",
    author: "Dreamwings",
    cover: "/images/download_file/download_file2.png",
    link: "https://www.dropbox.com/scl/fi/kkiyob6d84v7swp03puza/1-100-number-blocks-uzuulen.pdf?rlkey=tbdfatl48ghsinme8xq6ri20m&st=m4rz2jaq&dl=0",
  },
];

const FileContainer: React.FC = () => {
  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    navigate(`/podcast/${id}`);
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col>
          <Title style={{ fontSize: 23, paddingLeft: 30 }}>
            Татаж авж болох файлууд
          </Title>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <List
          dataSource={books}
          renderItem={(book) => (
            <List.Item className="dream-dowload-list-item">
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Image
                    src={book.cover}
                    style={{ width: "200px" }} // Responsive image styling
                    preview={false} // Optional: Disables the default preview feature
                  />
                </Col>
                <Col xs={24} sm={16}>
                  <List.Item.Meta
                    className="dream-download-list-content"
                    title={
                      <a
                        href={book.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <p style={{ fontWeight: 500 }}>
                            Номны нэр:{" "}
                            <span className="dream-download-list-title">
                              {book.title}
                            </span>
                          </p>
                          <p style={{ fontWeight: 500 }}>
                            Зохиогч:{" "}
                            <span className="dream-download-list-title">
                              Dreamwings
                            </span>
                          </p>
                        </div>
                      </a>
                    }
                    description={
                      <a
                        href={book.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button type="primary">Татаж авах</Button>
                      </a>
                    }
                  />
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Row>
    </div>
  );
};

export default FileContainer;
