import React from "react";
import { Layout, Menu, Image, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { useState } from "react";

const { Header } = Layout;
const { Title } = Typography;

const menuItems = [
  {
    key: "/",
    label: "Подкаст",
    style: { color: "#ffffff", fontWeight: 700 },
  },
  {
    key: "/online_webinar",
    label: "Онлайн вебинар",
    style: { color: "#ffffff", fontWeight: 700 },
  },
  {
    key: "/blogs",
    label: "Багш нарын блог",
    style: { color: "#ffffff", fontWeight: 700 },
  },
  {
    key: "/download_file",
    label: "Нэмэлт материал",
    style: { color: "#ffffff", fontWeight: 700 },
  },
];

const DreamHeader: React.FC = () => {
  const [current, setCurrent] = useState("/");
  const navigate = useNavigate();
  const handleClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
    setCurrent(e.key);
  };

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#002333",
      }}
    >
      <Image src="/images/logo.png" preview={false} width={95} />
      <Title
        style={{
          fontSize: 18,
          paddingLeft: 15,
          color: "#ffffff",
          textAlign: "center",
          display: "none",
        }}
        className="header-mobile-title"
      >
        МӨРӨӨДЛИЙН ЖИГҮҮР БОЛОВСРОЛЫН ТӨВ
      </Title>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={[current]}
        items={menuItems}
        style={{
          flex: 1,
          minWidth: 0,
          backgroundColor: "#002333",
        }}
        onClick={handleClick}
        className="desktop-menu"
      />
    </Header>
  );
};

export default DreamHeader;
